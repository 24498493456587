import {
  Advisor,
  AdvisorFirm,
} from '~/components/Form/InvestorGroupsSearchSelect/queries/useInvestorGroups';

export const DEFAULT_ADVISOR_FIRM: AdvisorFirm = {
  advisor_firm_name: 'All Firms',
  date_created: '',
  firm_code: '',
  firm_group: '',
  id: '',
  offering_docs_requirements: undefined,
} as AdvisorFirm;

const getUniqueAdvisorsFirmsSorted = (advisors: Advisor[] | undefined) =>
  advisors
    ?.reduce((acc, { advisor_firm: advisorFirm }) => {
      if (!advisorFirm) return acc;
      const firmWasNotAdded = !acc.find(firm => firm?.id === advisorFirm?.id);
      if (firmWasNotAdded) {
        acc.push(advisorFirm);
      }
      return acc;
    }, [] as AdvisorFirm[])
    ?.sort((a, b) => a?.advisor_firm_name.localeCompare(b?.advisor_firm_name));

const getUniqueAdvisorsFirms = (advisors: Advisor[] | undefined) => [
  DEFAULT_ADVISOR_FIRM,
  ...(getUniqueAdvisorsFirmsSorted(advisors) || []),
];

export default getUniqueAdvisorsFirms;
