import { UseQueryResult, UseQueryOptions, QueryFunction } from 'react-query';
import useAuth0Validation from '../useAuth0Validation';
import useCustomQuery from '../useCustomQuery';

type UseAuthenticatedQueryProps<ResultType = unknown> = {
  queryKey: any[] | readonly any[];
  queryFn?: QueryFunction<ResultType>;
  options?: UseQueryOptions<ResultType>;
};

const useAuthenticatedQuery = <ResultType = unknown, TError = Error>({
  queryKey,
  queryFn,
  options: { enabled = true, ...optionsProps } = {},
}: UseAuthenticatedQueryProps<ResultType>): UseQueryResult<
  ResultType,
  TError
> => {
  const isAuthenticated = useAuth0Validation();

  const queryResult = useCustomQuery<ResultType>(queryKey, queryFn, {
    ...optionsProps,
    enabled: !!(enabled && isAuthenticated),
  }) as UseQueryResult<ResultType, TError>;

  return queryResult;
};

export default useAuthenticatedQuery;
