export type SVGIconProps = {
  width?: string;
};

const GlasFundsLightLogo = ({ width = '200' }: SVGIconProps) => (
  <svg
    width={width}
    height="100%"
    viewBox="0 0 171 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.1179 15.0592V30.1182H0L3.34644 26.7717H26.7715V18.4056L30.1179 15.0592Z"
      fill="#A4B0BE"
    />
    <path
      d="M0 15.0591V0.000117242H30.1179L26.7715 3.34655H3.34644V11.7126L0 15.0591Z"
      fill="#A4B0BE"
    />
    <path
      d="M8.92941 11.2941L0 20.2235V24.9531L11.2942 13.6589L8.92941 11.2941Z"
      fill="#002D65"
    />
    <path
      d="M13.6593 11.2942H8.92969L16.4592 18.8237L18.824 16.4589L13.6593 11.2942Z"
      fill="#A4B0BE"
    />
    <path
      d="M30.1185 5.16469V9.89432L21.1891 18.8237H16.4595L30.1185 5.16469Z"
      fill="#002D65"
    />
    <path
      d="M39.7393 12.3678C39.7393 7.56905 42.4471 5.00847 46.5983 5.00847H46.7746C50.8963 5.00847 53.5751 7.56905 53.5751 12.3678H50.3073C50.3073 9.10004 49.2476 7.95222 46.7746 7.95222H46.5983C44.0372 7.95222 43.0065 9.10004 43.0065 12.3678V18.3145C43.0065 21.5817 44.0668 22.7301 46.5392 22.7301H46.6865C49.0418 22.7301 50.3073 21.4936 50.3073 18.3145V18.2258H47.0401V15.2235H53.5751V25.3793H50.5427V23.0541H50.1896C49.512 24.7904 48.0992 25.6738 45.9212 25.6738H45.7444C42.0059 25.6738 39.7393 23.1127 39.7393 18.3145V12.3678Z"
      fill="#002D65"
    />
    <path
      d="M60.4933 22.376H68.5298V25.3789H57.2847V5.30252H60.4933V22.376Z"
      fill="#002D65"
    />
    <path
      d="M78.9845 16.6653L77.0123 8.33439H76.6889L74.6866 16.6653H78.9845ZM69.1226 25.3789L74.4216 5.30252H79.279L84.4308 25.3789H81.0744L79.7202 19.6978H73.9509L72.5967 25.3789H69.1226Z"
      fill="#002D65"
    />
    <path
      d="M88.7585 19.1969C88.7585 21.9053 89.8182 22.7592 92.497 22.7592H92.6738C95.1463 22.7592 96.2651 21.9644 96.2651 19.9922C96.1179 18.7557 95.7352 18.1377 94.5573 17.5487C94.1161 17.3424 93.7921 17.1951 93.5863 17.107C93.3503 17.0189 92.9387 16.9012 92.3793 16.7239C91.8199 16.5476 91.4373 16.4299 91.2901 16.3714C87.6397 15.1053 85.9034 13.5152 85.9034 10.4242C85.933 6.98015 88.4936 5.00798 92.1144 5.00798H92.2912C96.4413 5.00798 99.0025 7.15695 99.0025 11.514H95.7648C95.7648 8.80563 94.7932 7.92217 92.2912 7.92217H92.1144C90.1417 7.92217 89.1706 8.74651 89.1706 10.4242C89.1706 11.3958 89.5237 12.1024 90.3486 12.6027C91.1724 13.1036 91.7022 13.3094 92.9683 13.692C95.1167 14.3691 96.7364 15.1343 97.8256 16.0473C98.9144 16.9598 99.4738 18.2844 99.4738 19.9922C99.4738 23.7307 96.8245 25.6733 92.6738 25.6733H92.497C88.2286 25.6733 85.5498 23.5244 85.5498 19.1969H88.7585Z"
      fill="#002D65"
    />
    <path
      d="M101.066 10.6602H103.745V8.56976C103.745 6.06774 105.423 4.18369 108.131 4.18369H111.016V7.06888H107.955C107.218 7.06888 106.983 7.27468 106.983 8.0109V10.6602H110.604V13.5158H106.983V25.3789H103.745V13.5158H101.066V10.6602Z"
      fill="#A4B0BE"
    />
    <path
      d="M112.458 10.6601H115.726V19.8154C115.726 21.7284 116.492 22.671 118.023 22.671H118.14C120.142 22.671 121.231 21.3464 121.231 19.1087V10.6601H124.469V25.3789H121.231V23.2594H120.907C120.171 24.8786 118.906 25.6733 117.11 25.6733H116.992C114.195 25.6733 112.458 23.6125 112.458 20.0508V10.6601Z"
      fill="#A4B0BE"
    />
    <path
      d="M128.151 25.3789V10.6602H131.39V12.7796H131.714C132.449 11.1605 133.715 10.3657 135.511 10.3657H135.628C138.425 10.3657 140.162 12.4265 140.162 15.9882V25.3789H136.894V16.2242C136.894 14.3106 136.129 13.3685 134.599 13.3685H134.481C132.479 13.3685 131.39 14.6932 131.39 16.9308V25.3789H128.151Z"
      fill="#A4B0BE"
    />
    <path
      d="M149.319 22.671C151.321 22.671 152.322 21.4049 152.322 18.8733V17.1661C152.322 14.6345 151.321 13.3684 149.319 13.3684H149.201C147.347 13.3684 146.435 14.7226 146.435 17.4606V18.6971C146.435 21.3463 147.347 22.671 149.201 22.671H149.319ZM143.196 17.2246C143.196 12.9563 145.051 10.3661 148.024 10.3661H148.112C149.996 10.3661 151.291 11.1609 151.968 12.78H152.322V4.18413H155.56V25.3788H152.381V23.0541H152.057C151.351 24.7904 150.055 25.6738 148.142 25.6738H148.083C144.992 25.6738 143.196 23.2599 143.196 18.9324V17.2246Z"
      fill="#A4B0BE"
    />
    <path
      d="M161.654 20.522C161.654 22.2583 162.567 22.9649 164.54 22.9649H164.628C166.336 22.9649 167.189 22.4356 167.189 21.3754C167.189 20.2275 166.247 19.8444 163.657 19.1383C160.477 18.2258 158.799 16.9012 158.799 14.5754C158.799 11.8375 160.801 10.307 164.098 10.307H164.186C167.689 10.307 169.868 11.9847 169.868 15.3702H166.688C166.659 13.8392 165.835 13.074 164.216 13.074H164.098C162.626 13.074 161.89 13.6038 161.89 14.634C161.89 15.6055 162.832 15.9882 165.305 16.6948C168.72 17.6664 170.25 18.7557 170.25 21.3754C170.25 24.1127 168.072 25.7324 164.628 25.7324H164.54C160.889 25.7324 158.505 24.0246 158.505 20.522H161.654Z"
      fill="#A4B0BE"
    />
  </svg>
);
export default GlasFundsLightLogo;
