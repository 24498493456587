import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import api from 'services/api';

const useAuth0Validation = () => {
  const {
    getAccessTokenSilently,
    isLoading: isLoadingAuth,
    isAuthenticated,
  } = useAuth0();
  const [bearerToken, setBearerToken] = useState('');
  const authHeader = api.defaults.headers.common?.Authorization;

  useEffect(() => {
    (async () => {
      if (!isLoadingAuth && isAuthenticated && !authHeader) {
        const token = await getAccessTokenSilently({
          audience: `${process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}/api/v2/`,
          scope: 'read:current_user',
        });
        setBearerToken(`Bearer ${token}`);
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
    })();
  }, [!isLoadingAuth && isAuthenticated && !authHeader]);

  return !!(bearerToken || authHeader);
};

export default useAuth0Validation;
