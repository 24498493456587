import { Icon, IconProps } from '@chakra-ui/react';

export type Directions = {
  up: string;
  down: string;
  left: string;
  right: string;
};
type ArrowIconProps = {
  direction: keyof Directions;
  minimal?: boolean;
  color?: string;
  size?: number;
} & IconProps;
const directionToAngleValues = {
  up: 270,
  left: 180,
  down: 90,
  right: 0,
};

const ArrowIcon = ({
  direction = 'up',
  minimal = false,
  color,
  size = 4,
  ...rest
}: ArrowIconProps) => {
  const iconColor = color || 'currentColor';

  return minimal ? (
    <Icon
      fill="none"
      boxSize={size}
      aria-label={`Arrow ${direction}`}
      alignItems="center"
      transform={`rotate(${
        directionToAngleValues[direction] + (minimal ? 90 : 0)
      }deg)`}
      viewBox="0 0 22.5 13.5"
      {...rest}>
      <path
        transform="rotate(90deg)"
        d="M1 8L7.5 1L14 8"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </Icon>
  ) : (
    <Icon
      boxSize={size}
      alignItems="center"
      aria-label={`Arrow ${direction}`}
      transform={`rotate(${directionToAngleValues[direction]}deg)`}
      viewBox="0 0 10 7"
      {...rest}>
      <path
        d="M5.84625 5.55094L5.4927 5.90449L6.1998 6.6116L6.55336 6.25804L5.84625 5.55094ZM8.5998 3.50449L8.95336 3.85805L9.30691 3.50449L8.95336 3.15094L8.5998 3.50449ZM6.55336 0.750939L6.1998 0.397385L5.4927 1.10449L5.84625 1.45805L6.55336 0.750939ZM6.55336 6.25804L8.95336 3.85805L8.24625 3.15094L5.84625 5.55094L6.55336 6.25804ZM8.95336 3.15094L6.55336 0.750939L5.84625 1.45805L8.24625 3.85805L8.95336 3.15094ZM8.5998 3.00449H0.799805V4.00449H8.5998V3.00449Z"
        fill={iconColor}
      />
    </Icon>
  );
};

export default ArrowIcon;
