import { useContext } from 'react';
import AdvisorOverrideContext from '../AdvisorOverrideContext';

const useAdvisorOverrideContext = () => {
  const advisorOverrideContext = useContext(AdvisorOverrideContext);
  if (!advisorOverrideContext) {
    throw new Error('useUserContext was used outside of its Provider');
  }
  return advisorOverrideContext;
};

export default useAdvisorOverrideContext;
