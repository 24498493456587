import { UseQueryOptions } from 'react-query';
import { Advisor } from '~/components/Form/InvestorGroupsSearchSelect/queries/useInvestorGroups';
import useAuthenticatedQuery from '~/hooks/useAuthenticatedQuery';
import { ResultPaginated } from '~/services/api/paginatedResponse';
import useUserIsAdvisorCustomerService from '../../hooks/useUserIsAdvisorCustomerService';
import advisorsKeys from './advisors';

const useAdvisorsAvailableToOverride = ({
  /**
   * Advisors override list is not commonly updated
   */
  staleTime = 60 * 60 * 1000,
  enabled = true,
}: UseQueryOptions = {}) => {
  const isAdvisorCustomerService = useUserIsAdvisorCustomerService();

  return useAuthenticatedQuery<ResultPaginated<Advisor[]>>({
    queryKey: advisorsKeys.override(),
    options: {
      staleTime,
      enabled: isAdvisorCustomerService && enabled,
    },
  });
};
export default useAdvisorsAvailableToOverride;
