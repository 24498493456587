import { useState } from 'react';
import useAdvisorOverrideContext from '~/components/AdvisorOverrideProvider/hooks/useAdvisorOverrideContext';
import {
  Advisor,
  AdvisorFirm,
} from '~/components/Form/InvestorGroupsSearchSelect/queries/useInvestorGroups';
import useAdvisorsAvailableToOverride from '~/components/SignInContainer/queries/advisors/useAdvisorsAvailableToOverride';
import getUniqueAdvisorsFirms, {
  DEFAULT_ADVISOR_FIRM,
} from '~/utils/getUniqueAdvisorsFirms';
import gtag from '~/lib/gtag';
import clearLocalStorageExcept from '~/utils/clearLocalStorageExcept';

export const sortAdvisorFullNameAlphabetically = (
  advisor1: Advisor,
  advisor2: Advisor,
) => advisor1.full_name.localeCompare(advisor2.full_name);

export const sortAdvisorByRole = (advisor1: Advisor, advisor2: Advisor) => {
  if (advisor1.role === 'CSA' && advisor2.role !== 'CSA') {
    return -1;
  }
  if (advisor1.role !== 'CSA' && advisor2.role === 'CSA') {
    return 1;
  }
  return 0;
};

const useAdvisorOverrideSelector = () => {
  const { data: advisors } = useAdvisorsAvailableToOverride();

  const [advisorOverrideId, setAdvisorOverrideId] = useAdvisorOverrideContext();

  const [advisorFirmId, setAdvisorFirmId] = useState<String>(
    DEFAULT_ADVISOR_FIRM.id,
  );

  const handleGaEvent = (
    action: 'Advisor Override Set Firm' | 'Set Advisor to Override',
  ) =>
    gtag.event({
      action,
      category: 'Button',
      label: 'User Menu Advisor Override',
    });

  const sortedAdvisors: Advisor[] =
    advisors?.results
      ?.sort(sortAdvisorFullNameAlphabetically)
      ?.sort(sortAdvisorByRole) || [];

  const filteredAdvisorsByFirm = sortedAdvisors?.filter(
    ({ advisor_firm: firm }) =>
      advisorFirmId ? advisorFirmId === firm?.id : true,
  );

  const advisorFirmList = getUniqueAdvisorsFirms(sortedAdvisors);

  const [selectedAdvisorToOverride] =
    advisors?.results?.filter(
      (advisor: Advisor) => advisor.id === advisorOverrideId,
    ) || [];

  const handleAdvisorSelection = (advisorId: string) => {
    handleGaEvent('Set Advisor to Override');
    setAdvisorOverrideId(advisorId);
    clearLocalStorageExcept('advisor_override_id');
  };

  const handleAdvisorFirmSelection = (firmId: String) => {
    handleGaEvent('Advisor Override Set Firm');
    setAdvisorFirmId(firmId);
  };

  const selectedAdvisorFirm = advisorFirmList?.find(
    ({ id }) => id === advisorFirmId,
  ) as AdvisorFirm;

  return {
    selectedAdvisorToOverride,
    filteredAdvisorsByFirm,
    selectedAdvisorFirm,
    advisorFirmList,
    handleAdvisorSelection,
    handleAdvisorFirmSelection,
  };
};

export default useAdvisorOverrideSelector;
