const clearLocalStorageExcept = (keyToKeep: string) => {
  if (typeof window === 'undefined') return;
  const localStorageKeysItems = Object.keys(window.localStorage);
  localStorageKeysItems.forEach(localStorageKey => {
    if (keyToKeep !== localStorageKey)
      window.localStorage.removeItem(localStorageKey);
  });
};

export default clearLocalStorageExcept;
