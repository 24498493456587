import { Box, useColorMode } from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';
import getWhiteLabelPartner from 'utils/whiteLabel';
import {
  WhiteLabelType,
  WHITE_LABEL_PARTNER_TEMPLATE_CONFIG,
} from 'utils/whiteLabel/defaultTypes';
import Image from 'next/image';
import GLASFundLightLogo from './GlasFundsLightLogo';
import GLASFundDarkLogo from './GlasFundsDarkLogo';

type LogoProps = {
  size: 'sm' | 'md' | 'lg';
  logoColorMode?: 'light' | 'dark';
  whiteLabelPartner?: WhiteLabelType | false;
};
const selectedWhiteLabelPartner = getWhiteLabelPartner();

const Logo = ({
  size = 'sm',
  logoColorMode,
  whiteLabelPartner = selectedWhiteLabelPartner,
}: LogoProps) => {
  const { colorMode } = useColorMode();

  const logoSize = {
    sm: '175',
    md: '250',
    lg: '330',
  }[size];

  const logoVersion = logoColorMode || colorMode;

  const selectedLogo: ReactElement = useMemo(() => {
    const defaultLogoOptions = {
      light: <GLASFundLightLogo width={logoSize} />,
      dark: <GLASFundDarkLogo width={logoSize} />,
    };
    if (whiteLabelPartner)
      return (
        <Box width={`${logoSize}px`}>
          <Image
            src={
              WHITE_LABEL_PARTNER_TEMPLATE_CONFIG[whiteLabelPartner]?.logo[
                logoVersion
              ]
            }
            layout="responsive"
            alt="Company Logo"
            objectFit="contain"
          />
        </Box>
      );

    return defaultLogoOptions[logoVersion];
  }, [logoVersion, whiteLabelPartner, logoSize]);

  return <Box>{selectedLogo}</Box>;
};
export default Logo;
