import defaultQueryFn from 'utils/defaultQueryFn';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import useAdvisorOverrideContext from '~/components/AdvisorOverrideProvider/hooks/useAdvisorOverrideContext';

const useCustomQuery = <ResultType = unknown, TError = Error>(
  queryKey: readonly any[],
  queryFn?: QueryFunction<ResultType>,
  { enabled = true, onError, ...rest }: UseQueryOptions<ResultType> = {},
): UseQueryResult<ResultType, TError> => {
  const [queryParam] = queryKey as string[];

  const [advisorOverrideId] = useAdvisorOverrideContext();

  const getAvailableRoutesToOverride = (route: string) => {
    if (route === 'user') return false;
    if (route === 'advisors/available_overrides') return false;
    return true;
  };

  const isRouteAvailableToOverride = getAvailableRoutesToOverride(queryParam);

  const queryKeyHasFilterObject = queryKey?.find(
    key => typeof key === 'object',
  );

  const formattedQueryKeyWithObject = queryKeyHasFilterObject
    ? queryKey
    : [...queryKey, {}];

  const queryKeyWithAdvisorOverrideId = formattedQueryKeyWithObject?.map(
    key => {
      if (typeof key === 'object')
        return {
          filters: {
            ...key?.filters,
            as_advisor:
              isRouteAvailableToOverride && advisorOverrideId
                ? advisorOverrideId
                : undefined,
          },
        };
      return key;
    },
  ) as QueryKey;

  const fetchFunction = queryFn || defaultQueryFn;

  const queryResult = useQuery(queryKeyWithAdvisorOverrideId, fetchFunction, {
    ...rest,
    enabled,
    onError: error => {
      onError?.(error);
    },
  }) as UseQueryResult<ResultType, TError>;

  return queryResult;
};

export default useCustomQuery;
